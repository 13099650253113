const initBlock = async function( $block ) {
	const $header = $block;
	const $mobileMenuBtn = $header.find( '.page-header__menu-btn' );
	const $nav = $header.find( '.page-header__nav-wrap' );

	$mobileMenuBtn.on( 'click', function( e ) {
		e.preventDefault();
		const $this = $( this );
		const $label = $this.find( '.label' );
		const label = $this.attr( 'data-label' );
		const labelClose = $this.attr( 'data-label-close' );

		if ( $this.hasClass( 'is-active' ) ) {
			$this.removeClass( 'is-active' );
			$nav.removeClass( 'is-active' );
			$( 'html' ).removeClass( 'scroll-off' );
			$( 'body' ).removeClass( 'scroll-off' );
			$label.html( label );
		} else {
			$this.addClass( 'is-active' );
			$nav.addClass( 'is-active' );
			$( 'html' ).addClass( 'scroll-off' );
			$( 'body' ).addClass( 'scroll-off' );
			$label.html( labelClose );
		}
	} );

	$nav.find( '.menu-item-has-children > a' ).on( 'click', e => {
		if ( $( window ).width() > 1023 ) { return false; }
		const $this = $( e.currentTarget );
		const $parentMenuItem = $this.closest( '.menu-item' );
		const $subMenu = $parentMenuItem.find( '.sub-menu' );
		$subMenu.slideToggle();
		$parentMenuItem.toggleClass( 'is-submenu-opened' );
	} );

	if ( $( '.section-hero--with-props' ).length ) {
		$header.addClass( 'page-header--hide-phone-mobile' );
	}
};

themeUtils.loadBlock( initBlock, 'header', '.page-header' );
